import React from "react";

export const PascalCase = (text) => {
	text = text.split(/[\s_.]+/);
	for (let i in text) {
		text[i] = text[i].slice(0, 1).toUpperCase() + text[i].slice(1, text[i].length)
	}
	text = text.join(" ")
	return text
}

export const convertDate = (d) => {
	d = new Date(d)
	let year = d.getFullYear()
	let month = d.getMonth() < 10 ? d.getMonth().toString().padStart(2, '0') : d.getMonth()
	let date = d.getDate() < 10 ? d.getDate().toString().padStart(2, '0') : d.getDate()
	return `${year}-${month}-${date}`
}

export const requiredStar = (sup = true) => {
	return (
		<React.Fragment>
			{
				sup ?
					<sup className='text-danger'>*</sup>
					:
					<span className='text-danger'>*</span>
			}
		</React.Fragment>
	)
}