import React from 'react'

import MainLayout from "../layouts/mainlayout/MainLayout"
import PageHeader from '../components/pageheader/PageHeader'
import ContactPage from '../components/contact/ContactPage'

const Contact = () => {
	return (
		<MainLayout title="Contact Us">
			<PageHeader title="Contact Us" />
			<ContactPage />
		</MainLayout>
	)
}

export default Contact