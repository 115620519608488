import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Toast } from 'bootstrap'

import { BASE_URL } from '../../utils/constants.js';
import { requiredStar } from '../../utils/helpers.js';

import './ContactPage.scss'


const ContactPage = () => {
	const data = useStaticQuery(graphql`
		query FlagLocation {
			file(relativePath: {eq: "flag-location.png"}) {
				childImageSharp {
				  fluid {
					...GatsbyImageSharpFluid
				  }
				}
			}
		}	  
	`)

	const createPrayerRequest = (e) => {
		e.preventDefault();
		fetch(`${BASE_URL}/api/open/prayerrequest`, {
			method: "POST",
			body: JSON.stringify({
				name: document.getElementById('name').value,
				email: document.getElementById('email').value,
				mobile: document.getElementById('mobile').value,
				request: document.getElementById('request').value,
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8"
			}
		})
			.then(response => response.json())
			.then(data => {
				console.log(data);
				if (data.Status) {
					e.target.reset()
					const toast = new Toast(document.getElementById('liveToast'))
					toast.show()
				}
			})
	}
	return (
		<div className='contact-layout'>
			<div className="request-mail">
				<h3 className="section-head text-center">For Prayer Request</h3>
				<div className="prayerForm mb-3">
					<form onSubmit={createPrayerRequest}>
						<div className="form-floating mb-3">
							<input type="name" className="form-control" id="name" required />
							<label htmlFor="name">Name{requiredStar(false)}</label>
						</div>

						<div className="form-floating mb-3">
							<input type="email" className="form-control" id="email" />
							<label htmlFor="email">Email</label>
						</div>

						<div className="form-floating mb-3">
							<input type="text" className="form-control" id="mobile" required />
							<label htmlFor="mobile">Mobile{requiredStar(false)}</label>
						</div>

						{/* <small className='text-info'>Either e-mail or mobile number required</small> */}

						<div className="form-floating">
							<textarea className="form-control" id="request" style={{ minHeight: "20rem" }} required></textarea>
							<label htmlFor="request">Request{requiredStar(false)}</label>
						</div>

						<div className="d-flex flex-row justify-content-end mt-3">
							<button type="submit" className="btn btn-dark">Send Prayer Request</button>
						</div>
					</form>
				</div>
			</div>
			<div className="contact-more">
				<h3 className="section-head">Office Timings & Address</h3>
				<p className='ps-3'>
					Full Life Centre A.G Church, <br />
					No.1, 1st Cross Street,<br />
					Murugan Nagar,<br />
					Thiruverkadu,<br />
					Chennai - 600 077.<br />
					10:00 AM - 6:00 PM
				</p>
				<h3 className="section-head mt-4">For Prayer Assistance</h3>
				<div className="ps-3">
					<strong className=''>Call us at:</strong>
					<a className='pr-link ms-2' href="tel:+919841677587">+91 9841677587</a>
					<br />
					<strong className=''>Email us at:</strong>
					<a className='pr-link ms-2' href="mailto:fulllifecentre@gmail.com">fulllifecentre@gmail.com</a>
				</div>
				<h3 className="section-head mt-4">Navigate to us</h3>
				<a href="https://www.google.com/maps/dir//Full+Life+Centre+A.+G.+Church,+1,+Ewsvaran+Nagar+1st+Cross+St,+Murugan+Nagar,+Thiruverkadu,+Chennai,+Tamil+Nadu+600077/@13.0795529,80.1205302,14.72z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3a526160197a69bd:0xb12ec237458879ba!2m2!1d80.1215408!2d13.0776338!3e0" target={"_blank"} rel="noreferrer">
					<div className='flag-location'>
						<Img fluid={data.file.childImageSharp.fluid} />
					</div>
				</a>
			</div>
			<div class="toast-container position-fixed top-0 end-0 p-3">
				<div id="liveToast" class="toast text-bg-success align-items-center" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="10000">
					<div class="d-flex flex-row align-items-center">
						<img src="/apple-touch-icon.png" class="rounded mx-2" height="30" alt="..." />
						<div class="toast-body">
							Your Prayer Request has been submitted.
						</div>
						<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactPage